import React, { useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Event, SocketContext } from 'SocketIO';
import { dispatch as CustomDispatch } from 'redux/actions/index';
import { selectOrders } from 'redux/selectors';

let nonStates = {};

export default function Orders(props) {
  const dispatch = useDispatch();
  const socket = useContext(SocketContext);

  const { symbol: { exchange, pair } = {} } = props;
  const key = exchange + '/' + pair;
  const ordersData = useSelector(selectOrders);
  const orders = ordersData[key];
  const getOrders = updatedKey => {
    if (!updatedKey) updatedKey = key;
    if (key !== updatedKey || !exchange) return;
    const latestOrderTime = orders && orders[0] && orders[0].time;
    socket.emit('GET_ORDERS', key, latestOrderTime, (updatedKey, _orders) => {
      _orders = _orders.map(order => {
        return {
          id: order[0],
          time: order[1],
          type: order[2],
          rate: order[3],
          amount: order[4],
          pnl: order[5],
        };
      });
      dispatch(CustomDispatch('addNewOrders', updatedKey, _orders));
    });
  };
  useEffect(() => {
    getOrders();
  }, [key]);

  return (
    <>
      <Event event="connect" handler={getOrders} />
      <Event event="NEW_ORDERS" handler={getOrders} />
    </>
  );
}
Orders.propTypes = {
  symbol: PropTypes.object.isRequired,
};
