import React, { memo, useCallback } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import tvNavigationColors from './tvNavigationColors';
import { ActionToggleOverview } from '../../../redux/actions/dashboard';
import { selectSideBarState } from '../../../redux/selectors/dashboard';

const useStyle = makeStyles({
  RightSidebarNavigation: {
    position: 'absolute',
    top: 'calc(98% - 40px)',
    background: `${tvNavigationColors.background}`,
    width: 10,
    zIndex: 1,
    left: -10,
    height: 40,
    display: 'inline-block',
    border: `1px solid ${tvNavigationColors.borderColor}`,
    borderRight: 'none',
    paddingLeft: '5px',
    borderRadius: 10,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    cursor: 'pointer',
  },
  arrow: {
    display: 'flex',
    flexDirection: 'column',

    '&:before, &:after': {
      content: '" "',
      width: 3,
      height: 12,
      borderRadius: 5,
      background: '#272727',
      display: 'inline-block',
    },

    '&:before': {
      transform: 'rotate(-168deg)',
      marginBottom: -2,
    },

    '&:after': {
      transform: 'rotate(-192deg)',
      marginTop: -2,
    },
    '&$open:before': {
      transform: 'rotate(-12deg)',
      marginBottom: -2,
    },

    '&$open:after': {
      transform: 'rotate(12deg)',
      marginTop: -2,
    },
  },
  open: {},
  arrowWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'transparent',
    border: 'none',
    width: 5,
  },
});

function RightSidebarNavigation() {
  const dispatch = useDispatch();
  const classes = useStyle();

  const sideBarState = useSelector(selectSideBarState);

  const handleMarketSidebar = useCallback(() => {
    dispatch(ActionToggleOverview());
  }, []);

  const overviewSelected = sideBarState === 'overview' ? classes.open : '';

  return (
    <div onClick={handleMarketSidebar}>
      <div
        className={classes.RightSidebarNavigation}
        style={!overviewSelected ? { zIndex: 0, background: '#1976d2', borderColor: '#1976d2' } : { zIndex: 0 }}
      ></div>
      <div className={classes.RightSidebarNavigation + ' ' + classes.arrowWrapper}>
        <div className={classes.arrow + ' ' + overviewSelected}></div>
      </div>
    </div>
  );
}

export default memo(RightSidebarNavigation);
