import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  IconButton,
  useTheme,
  MuiThemeProvider,
} from '@material-ui/core';
import { AddCircleOutline, HighlightOff, Remove } from '@material-ui/icons';
import PropTypes from 'prop-types';
import MaterialTable from '@material-table/core';
import createTheme from 'assets/jss/create-theme';
import { SocketContext } from 'SocketIO';
import { useSelector } from 'react-redux';
import { selectSettingsItem } from 'redux/selectors';

export default function Alerts(props) {
  const { entities = {}, pairKey } = props;
  const socket = useContext(SocketContext);
  const [conditions, setConditions] = useState([
    {
      condition: '',
      entityIndex: '',
      level: '',
    },
  ]);
  const [action, setAction] = useState('');

  const alertList = useSelector(selectSettingsItem('alertList'));
  const theme = useTheme();

  const handleChangeEntity = index => e => {
    let entityIndex = e.target.value;
    conditions[index].entityIndex = entityIndex;
    if (getTypes(entityIndex).indexOf(conditions[index].condition) === -1) {
      conditions[index].condition = '';
    }
    conditions[index].level = '';
    setConditions([...conditions]);
  };
  const handleChangeCondition = index => e => {
    conditions[index].condition = e.target.value;
    setConditions([...conditions]);
  };
  const handleChangeLevel = index => e => {
    conditions[index].level = e.target.value;
    setConditions([...conditions]);
  };
  const handleChangeAction = e => {
    setAction(e.target.value);
  };
  const handleCancelAlert = id => () => {
    socket.emit('delete_alert', id);
  };
  const handleSendAlert = () => {
    let list = [];
    conditions.forEach(item => {
      let entity = Object.keys(entities)[item.entityIndex] || '';
      let entityInfo = entities[entity].info;
      list.push({ entity, condition: item.condition, level: item.level, entityInfo });
    });
    socket.emit('create_alert', { info: list, action, key: pairKey });
    setAction('');
    setConditions([
      {
        condition: '',
        entityIndex: '',
        level: '',
      },
    ]);
  };
  const handleRemoveCondition = index => () => {
    conditions.splice(index, 1);
    setConditions([...conditions]);
  };
  const handleAddCondition = () => {
    conditions.push({
      condition: '',
      entityIndex: '',
      level: '',
    });
    setConditions([...conditions]);
  };
  const handleCloneAlert = alert => () => {
    socket.emit('create_alert', alert);
  };

  const isAbleToSend = () => {
    for (let i = 0; i < conditions.length; i++) {
      let condition = conditions[i];
      if (condition.condition === '' || condition.entityIndex === '') return false;
    }
    return !!action;
  };
  const getEntityKey = entityIndex => {
    return Object.keys(entities)[entityIndex] || '';
  };
  const getEntity = entityIndex => {
    return entities[getEntityKey(entityIndex)];
  };
  const getTypes = entityIndex => {
    let entityKey = getEntityKey(entityIndex);
    let type_key = 'default';
    Object.keys(types).forEach(key => {
      if (entityKey.startsWith(key)) {
        type_key = key;
      }
    });
    return types[type_key];
  };

  useEffect(() => {
    let flag = false;
    conditions.forEach(item => {
      let entity = Object.keys(entities)[item.entityIndex] || '';
      if (!entity) {
        item.entityIndex = '';
        item.condition = '';
        flag = true;
      }
    });
    if (flag) {
      setConditions([...conditions]);
    }
  }, []);

  return (
    <Box textAlign={'left'} display={'flex'} flexDirection={'column'} height={'100%'} pt={3}>
      {conditions.map((item, key) => (
        <Box key={key}>
          {key > 0 && (
            <Box display={'flex'} alignItems={'center'} mx={2}>
              <Box textAlign={'center'} flex={'auto'}>
                AND
              </Box>
              <IconButton color={'secondary'} onClick={handleRemoveCondition(key)}>
                <Remove />
              </IconButton>
            </Box>
          )}
          <Box mx={2} mb={2}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="demo-simple-select-outlined-label">Entity {key + 1}</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={item.entityIndex}
                onChange={handleChangeEntity(key)}
                label={'Entity' + (key + 1)}
              >
                <MenuItem value="">
                  <em>Please select</em>
                </MenuItem>
                {Object.keys(entities).map((label, key) => (
                  <MenuItem value={key} key={key}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box mx={2}>
            <FormControl variant="outlined" fullWidth disabled={item.entityIndex === ''}>
              <InputLabel id="demo-simple-select-outlined-label">Condition {key + 1}</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={item.condition}
                onChange={handleChangeCondition(key)}
                label={'Condition' + (key + 1)}
              >
                <MenuItem value="">
                  <em>Please select</em>
                </MenuItem>
                {getTypes(item.entityIndex).map((type, key) => (
                  <MenuItem value={type} key={key}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          {getEntityKey(item.entityIndex).startsWith('Fibonacci Retracements') && (
            <Box mx={2} mt={2}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="demo-simple-select-outlined-label">Fibonacci Retracements Level</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={item.level}
                  onChange={handleChangeLevel(key)}
                  label={'Fibonacci Retracements Level'}
                >
                  <MenuItem value="">
                    <em>Please select</em>
                  </MenuItem>
                  {getEntity(item.entityIndex).levels.map((level, key) => (
                    <MenuItem value={level.coeff} key={key}>
                      {level.coeff}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
          {getEntityKey(item.entityIndex).startsWith('Ichimoku') && (
            <Box mx={2} mt={2}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="demo-simple-select-outlined-label">Line</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={item.level}
                  onChange={handleChangeLevel(key)}
                  label={'Line'}
                >
                  <MenuItem value="">
                    <em>Please select</em>
                  </MenuItem>
                  <MenuItem value={0}>Conversion Line</MenuItem>
                  <MenuItem value={1}>Base Line</MenuItem>
                  <MenuItem value={2}>Lagging Span</MenuItem>
                  <MenuItem value={3}>Lead 1</MenuItem>
                  <MenuItem value={4}>Lead 2</MenuItem>
                </Select>
              </FormControl>
            </Box>
          )}
        </Box>
      ))}
      <Box mb={5} mx={2} mt={2} display={'flex'}>
        <Box flex={'auto'} mr={2}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="demo-simple-select-outlined-label">Action</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={action}
              onChange={handleChangeAction}
              label="Action"
            >
              <MenuItem value="">
                <em>Please select</em>
              </MenuItem>
              {actions.map((type, key) => (
                <MenuItem value={type} key={key}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box mr={2} display={'flex'} alignItems={'center'}>
          <IconButton color={'primary'} onClick={handleAddCondition}>
            <AddCircleOutline />
          </IconButton>
        </Box>
        <Button variant="contained" size="large" color="primary" disabled={!isAbleToSend()} onClick={handleSendAlert}>
          Send Alert
        </Button>
      </Box>

      <Box mb={2} mx={2} flex={'auto'}>
        <MaterialTable
          title={'Alert List'}
          style={{
            border: '1px solid #656565',
            overflow: 'hidden',
            backgroundColor: '#363636',
          }}
          options={{
            toolbar: true,
            sorting: true,
            paging: false,
            headerStyle: {
              padding: '6px 4px 6px 10px',
              whiteSpace: 'nowrap',
              backgroundColor: '#363636',
            },
            searchFieldStyle: {
              height: 40,
              width: 150,
              fontSize: 14,
              paddingLeft: 5,
            },
          }}
          columns={[
            {
              title: 'Symbol',
              field: 'key',
              cellStyle: (data, rowData) => ({
                whiteSpace: 'nowrap',
                padding: '6px 4px 6px 10px',
                color: rowData.action === 'Sell' ? theme.palette.secondary.main : theme.palette.primary.main,
              }),
            },
            {
              title: 'Status',
              field: 'status',
              cellStyle: (data, rowData) => ({
                whiteSpace: 'nowrap',
                padding: '6px 4px 6px 10px',
                color: rowData.status === 'waiting' ? '#b0934b' : theme.palette.primary.main,
              }),
            },
            {
              title: 'Entities',
              field: 'info',
              cellStyle: { whiteSpace: 'nowrap', padding: '6px 4px 6px 10px' },
              render: rowData => <span>{(rowData['info'] || []).length + ' entities'}</span>,
            },
            {
              title: 'Control',
              field: '',
              cellStyle: { padding: 0, textAlign: 'center' },
              headerStyle: { textAlign: 'center', paddingRight: 0 },
              render: rowData => (
                <MuiThemeProvider
                  theme={createTheme({
                    palette: {
                      secondary: {
                        main: theme.palette.error.main,
                      },
                    },
                  })}
                >
                  {rowData['status'] === 'processed' && (
                    <IconButton color={'primary'} onClick={handleCloneAlert(rowData)} title={'Renew alert'}>
                      <AddCircleOutline />
                    </IconButton>
                  )}
                  <IconButton color={'secondary'} onClick={handleCancelAlert(rowData['id'])} title={'Remove alert'}>
                    <HighlightOff />
                  </IconButton>
                </MuiThemeProvider>
              ),
            },
          ]}
          detailPanel={rowData => {
            return (
              <Box p={2}>
                {rowData.info.map((item, key) => (
                  <Box display={'flex'} key={key} py={'4px'}>
                    <Box flex={'auto'}>{item.entity}</Box>
                    <Box>{item.condition + (item.level ? ` (${item.level})` : '')}</Box>
                  </Box>
                ))}
              </Box>
            );
          }}
          data={alertList}
          onRowClick={(event, rowData, togglePanel) => togglePanel()}
        />
      </Box>
    </Box>
  );
}

Alerts.propTypes = {
  entities: PropTypes.object,
  pairKey: PropTypes.string.isRequired,
};
const types = {
  default: ['Crossing Up', 'Crossing Down', 'Greater Than', 'Less Than'],

  BB: [
    'Crossing Up High',
    'Crossing Up Low',
    'Crossing Down Middle',
    'Crossing Up Middle',
    'Crossing Down High',
    'Crossing Down Low',
    'Greater Than High',
    'Greater Than Low',
    'Less Than High',
    'Less Than Low',
  ],
  RSI: [
    'Greater Than Upper Limit',
    'Less Than Upper Limit',
    'Greater Than Lower Limit',
    'Less Than Lower Limit',
    'Crossing Up Upper Limit',
    'Crossing Down Upper Limit',
    'Crossing Up Lower Limit',
    'Crossing Down Lower Limit',
  ],
  Stochastic: [
    'Greater Than Upper Limit(K)',
    'Less Than Upper Limit(K)',
    'Greater Than Lower Limit(K)',
    'Less Than Lower Limit(K)',
    'Crossing Up Upper Limit(K)',
    'Crossing Down Upper Limit(K)',
    'Crossing Up Lower Limit(K)',
    'Crossing Down Lower Limit(K)',
    'Greater Than Upper Limit(D)',
    'Less Than Upper Limit(D)',
    'Greater Than Lower Limit(D)',
    'Less Than Lower Limit(D)',
    'Crossing Up Upper Limit(D)',
    'Crossing Down Upper Limit(D)',
    'Crossing Up Lower Limit(D)',
    'Crossing Down Lower Limit(D)',
  ],
  'Stochastic RSI': [
    'Greater Than Upper Limit(K)',
    'Less Than Upper Limit(K)',
    'Greater Than Lower Limit(K)',
    'Less Than Lower Limit(K)',
    'Crossing Up Upper Limit(K)',
    'Crossing Down Upper Limit(K)',
    'Crossing Up Lower Limit(K)',
    'Crossing Down Lower Limit(K)',
    'Greater Than Upper Limit(D)',
    'Less Than Upper Limit(D)',
    'Greater Than Lower Limit(D)',
    'Less Than Lower Limit(D)',
    'Crossing Up Upper Limit(D)',
    'Crossing Down Upper Limit(D)',
    'Crossing Up Lower Limit(D)',
    'Crossing Down Lower Limit(D)',
  ],
  MACD: ['MACD Crossing Up', 'MACD Crossing Down', 'MACD-H Crossing Up', 'MACD-H Crossing Down'],
  'Parallel Channel': [
    'Greater Than Upper Limit',
    'Less Than Upper Limit',
    'Greater Than Lower Limit',
    'Less Than Lower Limit',
    'Crossing Up Upper Limit',
    'Crossing Down Upper Limit',
    'Crossing Up Lower Limit',
    'Crossing Down Lower Limit',
  ],
};

const actions = ['Buy', 'Sell'];
